<template>
  <div class="px-3 py-2" v-if="info">
    <b-overlay :show="loading" rounded="sm">
      
      <ContactInformation :user_uuid="user_uuid" :functionbox_uuid="functionbox_uuid" :identifier="item.email"></ContactInformation>

      <hr class="pb-0 mb-0 mt-2" />

      <dl class="mt-2 clearfix">
        <dt>{{ $t("STATUS") }}</dt>
        <dd>
          <MessageStatus :status="item.status" show-text></MessageStatus>
        </dd>

        <dt v-if="item.status == 'VIEWED'">{{ $t("VIEWED") }}</dt>
        <dd v-if="item.status == 'VIEWED'">
          {{
            FormatDateTime(item.viewed_date)
          }}
        </dd>
        <dt>{{ $t("AUTH_METHODS.VERIFY") }}</dt>
        <dd>
          <b-img
            class="authIcon"
            :alt="showMethod"
            :src="authenticationImage"
          ></b-img>
          {{ showMethod }}
        </dd>
        <dt v-if="item.status == 'RECALLED'">{{ $t("RECALLED") }}</dt>
        <dd v-if="item.status == 'RECALLED'">
          {{
            FormatDateTime(item.recalled_date)
          }}
        </dd>
      </dl>

      <hr class="pb-0 mb-0 mt-2" />


      <b-button
        v-if="showRecall"
        variant="danger"
        class="btn-fill mt-2"
            size="sm"
        @click="recall"
        >{{ $t("RECALL") }}
      </b-button>
  
      <b-button
        v-if="showRecall"
        variant="primary"
            size="sm"
        class="btn-fill mt-2"
        @click="resend"
        >{{ $t("RESEND_NOTIFICATION") }}</b-button
      >
    </b-overlay>
  </div>
</template>
<script>
import ContactInformation from "../ContactInformation";
import MessageStatus from "../MessageStatus";
export default {
  components: { MessageStatus, ContactInformation },
  props: ["message_link", "response", "item", "index","functionbox_uuid","user_uuid"],
  data() {
    return {
      contactInfo: null,
      loading: false,
      info: this.item,
      showDialog: false,
      viewed: false,
      viewed_date: ""
    };
  },
  methods: {
    onClose: function() {
      this.showDialog = false;
    },
    viewMessage: function() {
      this.$emit("gotoMessage",this.info.message_uuid);
    },
    recall: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
            self.$http
              .post(
                self.user.hostname + "/message/link-recall",
                {
                  link_id: self.info.id,
                }
              )
              .then(function(response) {
                self.info.recalled = 1;
                self.$emit('RECALLED');
                self.info.status = 'RECALLED';
                self.loading = false;
                self.info.recalled_date = response.data.recalled_date;
                self.$noty.info(self.$t("SAVED"));
              })
              .catch(function() {
                self.loading = false;
              });
        }
      })
      .catch(function(){
      }); 
    },
    resend: function() {
      let self = this;   
      this.$bvModal.msgBoxConfirm(this.$t('ARE_YOU_SURE'), {
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        okTitle: this.$t('YES'),
        cancelTitle: this.$t('NO'),
        bodyClass: 'messagebox',
        footerClass: 'p-2  text-center messagebox',
        hideHeaderClose: false,
        centered: true
      })
      .then(function(value){
        if(value)
        {          
          self.loading = true;
          self.$http
            .post(
              self.user.hostname + "/message/link-resend",
              {
                link_id: self.info.id
              }
            )
            .then(function() {
              self.loading = false;
              self.info.sent = 1;
              self.info.status = 'NOT_VIEWED';
              self.$emit('SENT');
              self.$noty.info(self.$t("SENT"));
            })
            .catch(function() {
              self.loading = false;
            });
        }
      })
      .catch(function(){
      }); 
    }
  },
  computed: {
    showRecall(){
      return this.info.status == 'NOT_VIEWED' || this.info.status == 'SENT_WITHOUT_NOTIFICATION';
    },
    authenticationImage() {
      return "/img/auth/" + this.info.authentication_method + ".png";
    },
    popoverTarget() {
      return "SecureLink" + this.index;
    },
    showMethod() {
      return this.$t("AUTH_METHODS." + this.info.authentication_method);
    },
    showStatus() {
      if (this.info.status == 'RECALLED') {
        return this.$t("RECALLED");
      }
      if (this.info.status == 'VIEWED') {
        return this.$t("VIEWED");
      }
      if(this.info.status == 'SENT_WITHOUT_NOTIFICATION')
      {
        return this.$t("NOT_SENT");
      }
      if(this.info.status == 'NOT_VIEWED')
      {
        return this.$t("NOT_VIEWED");
      }
      return "";
    },
  },
  watch: {
    item: function(newItem, oldItem) {
      if (newItem.id != oldItem.id) {
        this.info = newItem;
      }
    },
  },
  mounted(){
  }
};
</script>
<style></style>
