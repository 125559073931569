var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.info
    ? _c(
        "div",
        { staticClass: "px-3 py-2" },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c("ContactInformation", {
                attrs: {
                  user_uuid: _vm.user_uuid,
                  functionbox_uuid: _vm.functionbox_uuid,
                  identifier: _vm.item.email,
                },
              }),
              _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
              _c("dl", { staticClass: "mt-2 clearfix" }, [
                _c("dt", [_vm._v(_vm._s(_vm.$t("STATUS")))]),
                _c(
                  "dd",
                  [
                    _c("MessageStatus", {
                      attrs: { status: _vm.item.status, "show-text": "" },
                    }),
                  ],
                  1
                ),
                _vm.item.status == "VIEWED"
                  ? _c("dt", [_vm._v(_vm._s(_vm.$t("VIEWED")))])
                  : _vm._e(),
                _vm.item.status == "VIEWED"
                  ? _c("dd", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.FormatDateTime(_vm.item.viewed_date)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _c("dt", [_vm._v(_vm._s(_vm.$t("AUTH_METHODS.VERIFY")))]),
                _c(
                  "dd",
                  [
                    _c("b-img", {
                      staticClass: "authIcon",
                      attrs: {
                        alt: _vm.showMethod,
                        src: _vm.authenticationImage,
                      },
                    }),
                    _vm._v("\n        " + _vm._s(_vm.showMethod) + "\n      "),
                  ],
                  1
                ),
                _vm.item.status == "RECALLED"
                  ? _c("dt", [_vm._v(_vm._s(_vm.$t("RECALLED")))])
                  : _vm._e(),
                _vm.item.status == "RECALLED"
                  ? _c("dd", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.FormatDateTime(_vm.item.recalled_date)) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c("hr", { staticClass: "pb-0 mb-0 mt-2" }),
              _vm.showRecall
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-fill mt-2",
                      attrs: { variant: "danger", size: "sm" },
                      on: { click: _vm.recall },
                    },
                    [_vm._v(_vm._s(_vm.$t("RECALL")) + "\n    ")]
                  )
                : _vm._e(),
              _vm.showRecall
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-fill mt-2",
                      attrs: { variant: "primary", size: "sm" },
                      on: { click: _vm.resend },
                    },
                    [_vm._v(_vm._s(_vm.$t("RESEND_NOTIFICATION")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }